import { Principal } from "@dfinity/agent"; 

const bunnyFactory = ({ IDL }) => {
    const Property = IDL.Record({ 'value' : IDL.Text, 'name' : IDL.Text });
    const MintRequest_2 = IDL.Record({
      'url' : IDL.Text,
      'dataurl' : IDL.Text,
      'accountid' : IDL.Text,
      'contentType' : IDL.Text,
      'data' : IDL.Vec(IDL.Nat8),
      'desc' : IDL.Text,
      'name' : IDL.Text,
      'properties' : IDL.Vec(Property),
    });
    const MintRequest = MintRequest_2;
return IDL.Service({
    'getPrice' : IDL.Func([], [IDL.Nat64], []),
    'getCurrentToken' : IDL.Func([], [IDL.Nat], ['query']),
    'nextAccount' : IDL.Func([], [], []),
    'claim' : IDL.Func([], [IDL.Nat], []),
    'mint' : IDL.Func([MintRequest], [IDL.Nat], []),
    'my_tokens' : IDL.Func([], [IDL.Vec(IDL.Nat)], []),
    'user_tokens' : IDL.Func([IDL.Principal], [IDL.Vec(IDL.Nat)], ['query']),
});
};

(async () => {


    const result = await window.ic.plug.requestConnect();
    const connectionState = result ? "allowed" : "denied";
    console.log(`The Connection was ${connectionState}!`);


    // Canister Ids
    const bunnyCanisterId = 'xkbqi-2qaaa-aaaah-qbpqq-cai';

    // Whitelist
    const whitelist = [
      bunnyCanisterId,
    ];
  
    // Make the request
    const isConnected = await window.ic.plug.requestConnect({
      whitelist,
    });
  
    // Get the user principal id
    const principalId = await window.ic.plug.agent.getPrincipal();
    console.log(`Plug's user principal Id is ${principalId}`);
    
    var a = document.createElement('a');
    a.setAttribute('href',"#");
    a.setAttribute('id',"pleaselink");
    a.setAttribute('class',"btn");

    a.innerHTML = "Please wait...";
    const b1 = document.createElement('br');
    const b2 = document.createElement('br');
    b1.setAttribute('id',"b1");
    b2.setAttribute('id',"b2");

    document.getElementById('message2').appendChild(b1);             
    document.getElementById('message2').appendChild(b2);             
    document.getElementById('message2').appendChild(a);  

    if(connectionState)
    {
  
        // Create an actor to interact with the NNS Canister
        // we pass the NNS Canister id and the interface factory
        const bunnyActor = await window.ic.plug.createActor({
        canisterId: bunnyCanisterId,
        interfaceFactory: bunnyFactory,
        });

        let myTokens  = await bunnyActor.user_tokens(principalId);  
        console.log(myTokens);

        if(myTokens.length > 0)
        {
            document.getElementById('pleaselink').remove();
            document.getElementById('b1').remove();
            document.getElementById('b2').remove();


            document.getElementById('isBunnyHolder').value = myTokens.length;

            var a = document.createElement('a');
            a.setAttribute('href',"#");
            a.setAttribute('id',"bunnylink");
            a.setAttribute('class',"btn");

            a.innerHTML = "Reveal 3D Bunny ";
            const b1 = document.createElement('br');
            const b2 = document.createElement('br');
            b1.setAttribute('id',"b1");
            b2.setAttribute('id',"b2");

            document.getElementById('message2').appendChild(b1);             
            document.getElementById('message2').appendChild(b2);             
            document.getElementById('message2').appendChild(a);      

            
        }
        else
        {

            document.getElementById('pleaselink').remove();
            document.getElementById('b1').remove();
            document.getElementById('b2').remove();

            var img = document.createElement('img');


            img.src = './resources/bunny.png';
            
            var a = document.createElement('a');
            a.setAttribute('href',"https://entrepot.app/marketplace/icpbunny");
            a.setAttribute('target',"_");
            a.setAttribute('class',"btn");


            a.innerHTML = "To control 3D Bunny. You need atleast one Bunny in your wallet.Buy ICPBunny ! ";

            const b1 = document.createElement('br');
            const b2 = document.createElement('br');
            b1.setAttribute('id',"b1");
            b2.setAttribute('id',"b2");

            //document.getElementById('image').appendChild(img); 
            document.getElementById('message').appendChild(b1);             
            document.getElementById('message').appendChild(b2);             
            document.getElementById('message').appendChild(a); 
            
            document.getElementById('isBunnyHolder').value = 0;


        }
    }
  })();

/***************************************************/
//Account ID
async function accountID()
{
  try {
      const index = document.getElementById("account-index").value.toString();
      const bunnyPrincpal = "vwwmp-om32r-tgzpy-3lx3m-a5qbm-5pst6-xfdoi-gtivj-trcp6-k3dch-5ae";
      const cont_principal = principalToAccountIdentifier(bunnyPrincpal,index);
      document.getElementById("account-name").value = cont_principal;
      
      // // console.log(cont_principal);
    
      connectWallet();
  } catch (error) 
  {
    console.error(error);
  } 

}

const principalToAccountIdentifier = (p, s) => {
  const padding = Buffer("\x0Aaccount-id");
  const array = new Uint8Array([
      ...padding,
      ...Principal.fromText(p).toBlob(),
      ...getSubAccountArray(s)
  ]);
  // console.log("Array Start");
  // console.log(array[array.length-1]);
  // console.log(array[array.length-2]);
  // console.log("Array End");


  const hash = sha224(array);
  const checksum = to32bits(getCrc32(hash));
  const array2 = new Uint8Array([
      ...checksum,
      ...hash
  ]);
  return toHexString(array2);
};

const toHexString = (byteArray)  =>{
  return Array.from(byteArray, function(byte) {
    return ('0' + (byte & 0xFF).toString(16)).slice(-2);
  }).join('')
}

const getSubAccountArray = (s) => {
  if (Array.isArray(s)){
    return s.concat(Array(32-s.length).fill(0));
  } else {
    //32 bit number only
    return Array(28).fill(0).concat(to32bits(s ? s : 0))
  }
};

/***************************************************/


async function  connectWallet() 
{
    // Canister Ids
    const bunnyCanisterId = 'xkbqi-2qaaa-aaaah-qbpqq-cai';

    // Whitelist
    const whitelist = [
      bunnyCanisterId,
    ];
  
    // Make the request
    const isConnected = await window.ic.plug.requestConnect({
      whitelist,
    });
  
    // Get the user principal id
    const principalId = await window.ic.plug.agent.getPrincipal();
  
    console.log(`Plug's user principal Id is ${principalId}`);
    
    let userPrincipal = principalId; //Principal.fromText(principalId);

    await window?.ic?.plug?.requestConnect({
        bunnyCanisterId,
        host:"https://mainnet.dfinity.network",
      });
    
    // Create an actor to interact with the NNS Canister
    // we pass the NNS Canister id and the interface factory
    const bunnyActor = await window.ic.plug.createActor({
      canisterId: bunnyCanisterId,
      interfaceFactory: bunnyFactory,
    });

    let myTokens  = await bunnyActor.user_tokens(userPrincipal);  
    
    console.log(myTokens);

}

/*
  document.getElementById("plugbutton").addEventListener("click", () => {
    connectWallet();
  });
  */